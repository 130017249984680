import React from 'react';
import Daniel from './others/Daniel.jpg';
import TopNav from './general/TopNav';
import Footer from './general/Footer';

const KnowMoreD = () => {
  return (
    <>
      <TopNav />
      <div className="bg-white shadow-lg rounded-lg p-6 max-w-full sm:max-w-lg md:max-w-xl lg:max-w-2xl mx-auto transform transition duration-300 hover:scale-105 hover:shadow-2xl flex flex-col sm:flex-row items-center sm:items-start mt-20">
        <div className="flex-1 sm:mr-6">
          <h2 className="text-2xl font-semibold text-gray-800">Daniel Chukwu</h2>
          <p className="text-gray-600 mt-2">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente harum soluta quod nisi nostrum minus molestiae omnis hic debitis quidem officia commodi placeat vitae repudiandae temporibus, culpa ab, cum quos.
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque enim animi aut voluptatum quia placeat ipsa aliquam, quidem eveniet libero repudiandae nihil minus sequi quasi nulla commodi aliquid veritatis cupiditate!
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim sint dolor repellat incidunt consequatur minima unde expedita, dignissimos, ducimus quasi ipsum eveniet, nam in nihil nulla sed consectetur aspernatur veniam!
          </p>
        </div>
        <div className="flex flex-col items-center sm:items-start mt-4">
          <img
            src={Daniel}
            alt="Daniel Chukwu"
            className="w-40 h-40 rounded-full object-cover sm:w-48 sm:h-48"
          />
          <p className="mt-5 ml-4 text-gray-800 bg-blue-300 p-2 font-bold text-center">Founder & CEO</p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default KnowMoreD;
