import React from 'react';
import Samuel from './others/Samuel.jpg';
import TopNav from './general/TopNav';
import Footer from './general/Footer';
const KnowMoreS = () => {
  return (
    <div>
          <>
      <TopNav />
      <div className="bg-white shadow-lg rounded-lg p-6 max-w-full sm:max-w-lg md:max-w-xl lg:max-w-2xl mx-auto transform transition duration-300 hover:scale-105 hover:shadow-2xl flex flex-col sm:flex-row items-center sm:items-start mt-20">
        <div className="flex-1 sm:mr-6">
          <h2 className="text-2xl font-semibold text-gray-800">Nwachukwu Samuel <p className='max-w-[70px] p-0.9 bg-blue-500 text-white overflow-hidden text-ellipsis'>(CTO)</p></h2>
          <p className="text-gray-600 mt-2">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente harumy soluta quod nisi nostrum minus molestiae omnis hic debitis quidem officia commodi placeat vitae repudiandae temporibus, culpa ab, cum quos.
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque enim animi aut voluptatum quia placeat ipsa aliquam, quidem eveniet libero repudiandae nihil minus sequi quasi nulla commodi aliquid veritatis cupiditate!
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim sint dolor repellat incidunt consequatur minima unde expedita, dignissimos, ducimus quasi ipsum eveniet, nam in nihil nulla sed consectetur aspernatur veniam!
          </p>
        </div>
        <div className="flex flex-col items-center sm:items-start mt-4">
          <img
            src={Samuel}
            alt="Daniel Chukwu"
            className="w-48 h-48 rounded-full mt-6 object-cover sm:w-48 sm:h-48"
          />
        </div>
      </div>
      <Footer />
    </>
    </div>
  )
}

export default KnowMoreS
